.aboutHero > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: max(calc(32px / 1.8), calc((5vw * 32) / 100));
  line-height: max(calc(53px / 1.8), calc((5vw * 53) / 100));

  color: #636363;
  padding: 100px 40px 100px 40px;
}
.main {
  padding: 10px;
}

.main > h1:nth-child(1) {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(40px / 1.8), calc((5vw * 40) / 100));
  color: #b5b5b5;
  margin: 0;
}

.main > h1:nth-child(2),
.main > h1:nth-child(3) {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(96px / 1.8), calc((5vw * 96) / 100));
  background: linear-gradient(96.21deg, #6ec1b3 2.17%, #2374ba 99.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
}

.main > p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  line-height: max(calc(39px / 1.8), calc((5vw * 39) / 100));
  /* or 39px */

  color: #636363;

  margin-top: 50px;
  margin-right: 70px;
}

.weDoSection {
  display: flex;
  height: 200px;
  margin-bottom: 50px;
}

.weDoSection > span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;

  font-size: max(calc(64px / 1.8), calc((5vw * 64) / 100));
  line-height: max(calc(105px / 1.8), calc((5vw * 105) / 100));

  display: flex;
  align-items: center;
  text-align: center;

  background: linear-gradient(96.21deg, #6bc19a 2.17%, #325ca8 99.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  position: relative;
  padding: 0px 30px 30px 30px;
  margin-bottom: auto;
}

.weDoSection > p {
  border-left: 1px solid #2374ba;

  font-family: "Lato";
  font-style: normal;
  font-weight: 500;

  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  line-height: max(calc(39px / 1.8), calc((5vw * 39) / 100));

  color: #636363;
  padding: 0px 30px 30px 30px;
}

@media only screen and (max-width: 600px) {
  .aboutHero > p {
    padding: 50px 10px 50px 10px;
  }
}
