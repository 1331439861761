.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20%;
}
.form > h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;

  font-size: max(calc(96px / 1.8), calc((5vw * 96) / 100));
  line-height: max(calc(158px / 1.8), calc((5vw * 158) / 100));

  display: flex;
  align-items: center;

  background: linear-gradient(96.21deg, #6bc19a 2.17%, #325ca8 99.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.form > input:nth-child(1),
.form > input:nth-child(2),
.form > input:nth-child(3),
.form > input:nth-child(4),
.form > textarea {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  line-height: max(calc(29px / 1.8), calc((5vw * 29) / 100));
  display: flex;
  align-items: center;
  color: black;

  border: 1px solid #b5b5b5;
  border-radius: 36px;
  width: max(calc(565px / 1.8), calc((5vw * 565) / 100));
  height: max(calc(72px / 1.8), calc((5vw * 72) / 100));
  margin-block: 13px;
  padding-left: 32px;
}

.form > textarea {
  height: 246px;
  padding-top: 32px;
}

.form > input::placeholder,
.form > textarea::placeholder {
  color: #b5b5b5;
}

.hasError {
  border: 1px solid red !important;
}
.img {
  height: max(calc(1285px / 1.8), calc((5vw * 1285) / 100));
  width: max(calc(910px / 1.8), calc((5vw * 910) / 100));
  margin: 0px 50px 0px 50px;
}

.submitBtn {
  width: max(calc(236px / 1.8), calc((5vw * 236) / 100));
  height: max(calc(72px / 1.8), calc((5vw * 72) / 100));

  background: linear-gradient(95.84deg, #6bc19a -0.8%, #325ca8 101.33%);
  border-radius: 36px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  line-height: max(calc(29px / 1.8), calc((5vw * 29) / 100));
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  border: 0px;
  margin-left: auto;
}
