.servicesTitle,
.approachTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicesTitle > h1:nth-child(1),
.approachTitle > h1:nth-child(1) {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  /* font-size: 40px; */
  font-size: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));

  display: flex;
  align-items: center;
  text-align: center;

  color: #b5b5b5;
}

.servicesTitle > h1:nth-child(2),
.approachTitle > h1:nth-child(2) {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  /* font-size: 96px; */
  font-size: max(calc(96px / 1.8), calc((5.8vw * 96) / 100));
  display: flex;
  align-items: center;
  text-align: center;

  background: linear-gradient(96.21deg, #325ca8 2.17%, #325ca8 99.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.approachSection {
  margin-top: 150px;
  background: url("../assets/images/approaches/dots.png");
  background-repeat: no-repeat;
}
