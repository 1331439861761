.hero {
  /* padding-top: 287px; */
  margin: 10px;
}

.hero > h1:nth-child(1) {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(40px / 2), calc((5vw * 40) / 100));
  color: #b5b5b5;
  margin: 0;
}

.hero > h1:nth-child(2),
.hero > h1:nth-child(3) {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(96px / 2), calc((5vw * 96) / 100));
  background: linear-gradient(96.21deg, #6ec1b3 2.17%, #2374ba 99.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
}

.hero > p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  color: #000000;
  margin-top: 50px;
  margin-right: 70px;
}

.servicesHeroSection {
  /* position: absolute;
  top: 0; */
}
.productLaunchingContent {
  margin: 0px;

  margin-right: max(calc(100px / 2), calc((5vw * 100) / 100));
  margin-left: max(calc(50px / 2), calc((5vw * 50) / 100));
}
.productLaunchingContent > h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(64px / 1.8), calc((5vw * 64) / 100));

  display: flex;
  align-items: center;

  color: #000000;
}

.productLaunchingContent > p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));

  color: #000000;
}

.servicesBusinessStrategySection {
  margin-top: 100px;
}

.businessStrategyContent {
  /* margin: 100px; */
  margin: max(calc(100px / 2), calc((5vw * 100) / 100));
}
.businessStrategyContent > h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(64px / 1.8), calc((5vw * 64) / 100));

  display: flex;
  align-items: center;

  color: #000000;
}

.businessStrategyContent > p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));

  color: #636363;
}

.businessArchitectureContent {
  margin: max(calc(70px / 2), calc((5vw * 70) / 100));
}

.businessArchitectureContent > h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(64px / 1.8), calc((5vw * 64) / 100));

  display: flex;
  align-items: center;

  color: #000000;
}

.businessArchitectureContent > p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));

  color: #636363;
}

.content {
  margin: max(calc(70px / 2), calc((5vw * 70) / 100));
}

.content > h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(64px / 1.8), calc((5vw * 64) / 100));
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  color: #000000;
}

.content > p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  line-height: max(calc(39px / 1.8), calc((5vw * 39) / 100));
  color: #636363;
}

.content > p > span {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: max(calc(24px / 1.8), calc((5vw * 24) / 100));
  line-height: max(calc(39px / 1.8), calc((5vw * 39) / 100));

  color: #12c2e9;
}

.gradientBox {
  background: linear-gradient(109.4deg, #6ec1b3 -23.27%, #2374ba 127.72%);
  border-radius: 19px;
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.gradientBox > div > h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(32px / 1.8), calc((5vw * 32) / 100));
  line-height: max(calc(102px / 1.8), calc((5vw * 102) / 100));
  display: flex;
  align-items: center;

  color: #ffffff;
}
.gradientBox > div > ul > li {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(20px / 1.8), calc((5vw * 20) / 100));
  line-height: max(calc(64px / 1.8), calc((5vw * 64) / 100));

  text-transform: uppercase;

  color: #ffffff;
}

.servicesBusinessArchitectureSection {
  padding-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .hero > p {
    margin-right: 0px;
  }

  .businessDEV {
    margin-right: max(calc(100px / 2), calc((5vw * 100) / 100));
    margin-left: max(calc(50px / 2), calc((5vw * 50) / 100));
  }

  .businessStrategyContent {
    margin-right: max(calc(100px / 2), calc((5vw * 100) / 100));
    margin-left: max(calc(50px / 2), calc((5vw * 50) / 100));
  }

  .gradientBox {
    padding: 10px;
  }
}
