.link,
.link,
.link,
.link > div > span {
  font-family: "Lato" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.footerLink,
.footerLink > div,
.footerLink > div > span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  /* font-size: 32px; */

  font-size: max(calc(28px / 2), calc((5.8vw * 28) / 100)) !important;

  display: flex;
  align-items: center;

  color: #ffffff;
  justify-content: flex-start !important;
}

.footerInput {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 1);
  margin-top: 40px;
  height: 62px;

  color: white;
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
}

.subscribeBtn {
  width: 235px !important;
  height: 62px !important;
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}
